import React from "react"
import { Router } from "@reach/router"
import LayoutFull from "../components/layout_full"
import PublicRequest from '../components/app_pages/public_request'

class AppPage extends React.Component {

  render() {
    return(
      <LayoutFull>
        <Router basepath="requests" className="w-100">
          <PublicRequest path=":uuid" />
        </Router>
      </LayoutFull>
    )
  }
}

AppPage.propTypes = {}
export default AppPage
