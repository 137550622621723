import React from "react"
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { Preview } from './_preview'
import { celebrate } from './_celebrate'
import {
  API_PATH,
  API_PUBLIC_REQUEST_UPLOAD,
  API_PUBLIC_REQUEST_UPLOAD_COMPLETE
} from '../../const/api_paths'

class UploadPublic extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      done: false,
      upload_uuid: null
    }
  }

  render() {
    return(
      <>
        {this.dragArea()}
        {this.thankYou()}
      </>
    )
  }

  dragArea() {
    return(
      <Dropzone
        getUploadParams={this.getUploadParams}
        onChangeStatus={this.handleChangeStatus}
        accept="*"
        canRemove={false}
        multiple={false}
        submitButtonContent=""
        submitButtonDisabled={true}
        maxFiles={1}
        SubmitButtonComponent={props => null}
        PreviewComponent={Preview}
      />
    )
  }

  thankYou() {
    if(this.state.done) {
      return(
        <div className="mt-5 text-center text-success">
        <h3>Thank You for uploading...</h3>
        </div>
      )
    }
  }

  handleChangeStatus = (meta, status) => {
    // console.log('handleChangeStatus')
    // console.log(meta)
    // console.log(status)

    if(status === 'done') {
      this.saveCompleteUpload()
      this.setState({done: true})
      celebrate()
      setTimeout(()=>{celebrate()}, 2000)
      setTimeout(()=>{celebrate()}, 4000)
    }
  }

  getUploadParams = async (d) => {
    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var options = {
      method:   "POST",
      mode:     "cors",
      cache:    "no-cache",
      headers:  headers,
      body:     JSON.stringify({
                  file_name: d.file.name,
                  file_type: d.file.type,
                  file_size: d.file.size,
                  uuid: this.props.uuid
                })
    }

    var url_option = await fetch(API_PATH(API_PUBLIC_REQUEST_UPLOAD), options)
    .then(res => res.json())
    .then(res=> {
      this.setState({upload_uuid: res.file_uuid})
      return {
        method: 'put',
        url: res.signedUrl,
        body: d.file
      }
    })
    return url_option
  }

  saveCompleteUpload = async () => {
    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var options = {
      method:   "POST",
      mode:     "cors",
      cache:    "no-cache",
      headers:  headers,
      body:     JSON.stringify({
                  uuid: this.props.uuid,
                  upload_uuid: this.state.upload_uuid
                })
    }

    await fetch(API_PATH(API_PUBLIC_REQUEST_UPLOAD_COMPLETE), options)
    .then(res => res.json())
    .then(res=> {})
  }

}

UploadPublic.propTypes = {}
export default UploadPublic