import React from "react"
import SEO            from "../seo"
import UploadPublic   from "../app/upload_public"

import {
  getRequestInfo,
} from '../../actions/PublicRequestAction'

class AppPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      error: ""
    }
  }

  componentDidMount() {
    var data = {uuid: this.props.uuid}
    getRequestInfo(data, {
      success: (res) => {
        this.setState({valid: true})
      },
      error: (res) => {
        this.setState({valid: false, error: res})
      }
    })
  }

  render() {
    return(
      <>
        <SEO title="Upload Request" />
        <div className="p-5">
          {this.uploadForm()}
        </div>
      </>
    )
  }

  uploadForm() {
    if(this.state.valid) {
      return <UploadPublic uuid={this.props.uuid} />
    } else {
      return(
        <div className="alert alert-danger text-center p-4" role="alert">
          {this.state.error}
        </div>
      )
    }
  }

}

AppPage.propTypes = {}
export default AppPage
