import {
  doAuthenticatedPost
} from './_methods'

import {
  API_PUBLIC_REQUEST_INFO
} from '../const/api_paths'

export const getRequestInfo = (data, callback) => {
  doAuthenticatedPost(API_PUBLIC_REQUEST_INFO, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}